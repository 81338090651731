.mat-mdc-slide-toggle.mat-accent {
	--mdc-switch-selected-pressed-track-color: var(--primary-contrast-color);
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track {
	--mdc-switch-selected-hover-track-color: var(--primary-contrast-color);
	--mdc-switch-selected-focus-track-color: var(--primary-contrast-color);
	--mdc-switch-selected-track-color: var(--primary-contrast-color);
	--mdc-slider-active-track-color: var(--primary-contrast-color);
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
	--mdc-switch-selected-handle-color: var(--primary-color);
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__track::after {
	border: 2px solid var(--primary-color);
}
