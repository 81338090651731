:root {
	/*
    Please use the following template to add new variables, dark/light valuations are optional.

    --<name>-color: <color>;
    --<name>-contrast-color: <contrast-color>;
    --<name>-dark-color: <dark-color>;
    --<name>-dark-contrast-color: <dark-contrast-color>;
    --<name>-light-color: <light-color>;
    --<name>-light-contrast-color: <light-contrast-color>;
  */
	--primary-color: #f15a24;
	--primary-contrast-color: #ffffff;
	--primary-light-color: #f26b3a;

	--primary-font-color: #344767;
	--primary-font-light-color: #7b809a;
	--primary-font-contrast-color: #ffffff;

	--background-color: #f0f2f5;

	--border-radius: 12px;

	/* TODO (Tien Luu): Update value for sm */
	--space-sm: 25px;

	--space-md: 25px;

	/* TODO (Tien Luu): Update value for lg */
	--space-lg: 25px;

	--font-family: Poppins;
}
